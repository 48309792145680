import React from 'react'

// App components
import Layout from '../../components/layout'
import PageTitle from '../../components/PageTitle'

// App utils
import { rhythm } from '../../utils/typography'
import { themeStyles, presets, colors } from '../../utils/theme'
import typography from '../../utils/typography'

const typographyJson = typography.toJSON()

const styles = {
  ol: {
    counterReset: 'counter',
    listStyle: 'none',
  },
  li: {
    marginBottom: '15px',
    position: 'relative',
    ':before': {
      content: `counter(counter) ". "`,
      counterIncrement: 'counter',
      color: colors.brightBlue,
      fontWeight: typographyJson.h3.fontWeight,
      display: 'inline-block',
      position: 'absolute',
      left: '-2em',
    },
  },
}

class Page extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <div css={themeStyles.contentWidth}>
          <PageTitle
            title="COVID-19 GUIDELINES"
            bannerKey={'COVID'}
            backgroundPosition="center"
            titleBackgroundColor="rgba(0,0,0,0)"
            titleStyle={{
              position: 'relative',
              maxWidth: '650px',
              lineHeight: rhythm(1.5),
              [presets.Mobile]: {
                fontSize: rhythm(1.3),
                letterSpacing: '15px',
                lineHeight: rhythm(2),
              },
            }}
            containerStyle={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          />
          <div
            css={{
              width: '100%',
              backgroundColor: colors.brightBlue,
              textAlign: 'center',
              padding: '30px',
            }}
          >
            <h1
              css={{
                color: 'white',
                margin: 0,
                maxWidth: '500px',
                margin: '0 auto',
              }}
            >
              NEW Guidelines for Appointments at Collins Orthodontics
            </h1>
          </div>
          <div
            css={[
              themeStyles.textPadding,
              { textAlign: 'center', maxWidth: '950px', margin: '0 auto' },
            ]}
          >
            <p css={{ marginTop: '30px', marginBottom: '50px' }}>
              Safe service is our top priority at Collins Orthodontics - for
              both our patients and our team. In order to deliver your care in
              the safest manner, here are the steps we ask you to take in
              preparation for your upcoming appointment:
            </p>
            <div
              css={{
                ...themeStyles.innerTextBreak,
                border: `3px solid ${colors.brightBlue}`,
                padding: '10px 40px',
                paddingTop: '30px',
                textAlign: 'left',
                marginTop: '60px',
                marginBottom: '30px',
                [presets.Tablet]: {
                  padding: '20px 70px',
                  paddingTop: '50px',
                  marginTop: '40px',
                },
              }}
            >
              <div
                css={{
                  padding: '20px',
                  backgroundColor: colors.brightBlue,
                  color: 'white',
                  maxWidth: '450px',
                  textAlign: 'center',
                  margin: '0 auto',
                  marginTop: '-80px',
                  marginBottom: '20px',
                  fontSize: '1.2em',
                }}
              >
                Please answer "YES" or "NO" to each question:
              </div>
              <ol css={styles.ol}>
                <li css={styles.li}>
                  Do you have a fever or above normal temperature?
                </li>
                <li css={styles.li}>
                  Have you experienced shortness of breath or had trouble
                  breathing?
                </li>
                <li css={styles.li}>Do you have a dry cough?</li>
                <li css={styles.li}>Do you have a runny nose?</li>
                <li css={styles.li}>
                  Have you recently lost or had a reduction in your sense of
                  smell?
                </li>
                <li css={styles.li}>Do you have a sore throat?</li>
                <li css={styles.li}>
                  Have you been in contact with someone who has tested positive
                  for COVID-19?
                </li>
                <li css={styles.li}>Have you tested positive for COVID-19?</li>
                <li css={styles.li}>
                  Have you been tested for COVID-19 and are awaiting results?
                </li>
                <li css={styles.li}>
                  Have you traveled outside the United States by air or cruise
                  ship in the past 14 days?
                </li>
              </ol>
            </div>
            <h3>
              If you have answered{' '}
              <strong css={{ color: colors.brightBlue }}>"YES"</strong> to any
              of the above questions, then we ask that you STAY HOME, call our
              office to reschedule your appointment
              {` `}
              <a css={{ textDecoration: 'none' }} href="tel:+15072585400">
                (507-258-5400)
              </a>
              , and contact your primary care physician.
            </h3>
            <h1 css={themeStyles.blueText}>Upon Arrival:</h1>
            <p>
              Put on your <strong>cloth mask</strong> before leaving your car.
            </p>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Page
